.photoContainer{

        display: flex;
        justify-content: center;
        width: 100%;


    .title{
        font-size: 3.75rem;
        font-family: "Nyala", "Helvetica", "Arial", sans-serif;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: 7px;
        margin-bottom: 20px;
        text-align: left;
        margin-top: 100px;
        margin-bottom: 50px;
    }

    .photo{
        width: 97%;
        height: 224px;
        background: yellow;
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
    }

}