.contentContainer{

    width: 80%;
    margin: 0 auto;
    padding-top: 10px;
    // background-color: rgb(245, 245, 245);
  background: transparent;

  .divider{

    margin-top: 100px;
    margin-bottom: 100px;
  }
  

}