.App {
  text-align: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  /* background-color: rgb(245, 245, 245); */
  background: rgba(253,213,141,0.4);
  min-height: 100vh;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


@font-face {
  font-family: "Roboto";
  src: url("./Assestes/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Thin";
  src: url("./Assestes/fonts/Roboto-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto Medium";
  src: url("./Assestes/fonts/Roboto-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto Light";
  src: url("./Assestes/fonts/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto Bold";
  src: url("./Assestes/fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Nyala";
  src: url("./Assestes/fonts/nyala/nyala.ttf") format("truetype");
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slick-next:before , .slick-prev:before {
font-size: 25px !important;
color:  #962618 !important;

}

.slick-prev:before{
  margin-left: -30px !important ;
}

.titleCommon{
  font-size: 3.75rem;
  font-family: "Nyala", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 7px;
  margin-bottom: 20px;
  text-align: left;
  margin-top: 100px;
  margin-bottom: 50px;

}


.titleReach{
  font-family: 'Roboto Bold';
  font-size: 20px;
    }

    .mail{
text-decoration: none;
    }
   
  .slick-slider {
    width: 92vw !important;
  }