.bookDetailsContainer {
  display: flex;
  justify-content: space-between;
  text-align: left;
  .BookDetails {
    max-width: 100%;
    padding-top: 60px;
    .title {
      font-size: 3.75rem;
      font-family: "Nyala", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 7px;
      margin-bottom: 0px;
      margin-top: 10px;
      text-transform: uppercase;
    }
    .desc {
        font-size: 1rem;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        opacity: 0.8;
        width: 70%;

        .bold{
          // font-family: "Roboto Bold" !important;
        }

        p{
          margin-top: 6px;
          margin-bottom: 6px;
        }
        
    }
   
}
    .bookCoverContainer {
      width: 300px;
      padding-top: 100px;
      // box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
      // padding: 18px;
      // height: fit-content;
      // background: white;

      .bookCover {
        width: 300px;
      }
      .findButton{
        width: 100%;
        margin-top: 20px;
                background-color: #962618;
                color: #f6c280;
                box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
                text-decoration: none;
                padding: 6px 16px;
            font-size: 0.875rem;
            min-width: 64px;
            box-sizing: border-box;
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            font-weight: 500;
            line-height: 1.75;
            border-radius: 4px;
            letter-spacing: 0.02857em;
            cursor: pointer;
            border: none;
            }
    
  }
}
