.reviewContainer{
    padding-top: 100px;
    justify-content: center;
    max-width: 50%;
    padding-left: 100px;
    text-align: left;

    .titleReview{
        font-size: 3.75rem;
        font-family: "Nyala", "Helvetica", "Arial", sans-serif;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: 7px;
        margin-bottom: 20px;
        text-align: left;
        margin-top: 0px;
        margin-bottom: 10px;

    }   

}