.mainNavbar {
  height: 22px;
  position: absolute;
  left: 0;
  right: 0;
  // top: 0;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* width: 100%; */
  padding: 20px 60px 20px 60px;
  // background: white;
  background-color: #962618;
  color: #f6c280;
  z-index: 2;
  .namAndSlogan {
    text-align: left;
    .name {
      font-size: 33px;
      font-family: "Nyala";
    }
    .slogan {
      font-size: 11px;
      // color: #696969;
      color: #f6c280;
      width: 178px;
    }
  }

  .navbarContainer {
    display: flex;
    justify-content: space-around;
    width: 82%;
    align-items: center;
    .eachItem {
    }

    a{
      text-decoration: none !important;
    }
    p {
      // color: #696969;
      color: #f6c280;
      cursor: pointer;
    }
  }

}
.close{
  width: 4%;
  position: absolute;
  right: 14px;
  top: 14px;
  cursor: pointer;
}
