

.aboutMeContainer{

display: flex;
justify-content: space-between;
padding-top: 0px;
width: 80%;
margin: 0 auto;
// background-color: rgb(245, 245, 245);
background: transparent;
.photoscontainer{

    width: 58%;
padding-top: 100px;


}

    .photo1, .photo2, .photo3, .photo4{

        width: 20%;
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
        padding: 8px;
        height: fit-content;
        background: white;
    }
    
    .photo3{    transform: rotate(90deg);}

    .aboutMeText{

        max-width: 34%;
        text-align: left;
        font-size: 1rem;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        opacity: 0.8;
padding-top: 40px;


        .aboutMeTitle{
            font-size: 3.75rem;
            font-family: "Nyala", "Helvetica", "Arial", sans-serif;
            font-weight: 500;
            line-height: 1.2;
            letter-spacing: 7px;
            margin-bottom: 20px;
            text-align: left;
            margin-top: 100px;
            margin-bottom: 50px;
        
        }
    }

}


